/**
 * Global main namespace for the application
 *
 * Entry point of the application
 * @namespace APP
 */

var APP = APP || function () {
	'use strict';
};

/**
 * Main application class
 *
 * @namespace APP
 * @name APP.Head
 */
APP.Head = (/** @lends APP.Head */function () {
	'use strict';

	var instance,
		fontConfig = {
			custom: {
				// families: ['helvetica-neue-regular', 'helvetica-neue-bold', 'helvetica-neue-light', 'helvetica-neue-medium'],
				urls: [document.body.getAttribute('data-asset-path') + '/css/font.css']
			}
		};

	/**
	 * Initialize application and needed subclasses
	 *
	 * @public
	 * @returns {void}
	 */
	function init() {
		loadWebFonts();
	}

	/**
	 * Initialize Google Webfont loader
	 *
	 * @private
	 * @returns {void}
	 */
	function loadWebFonts() {
		WebFont.load(fontConfig);
	}

	return {

		/**
		 * Get singleton APP.Head instance
		 *
		 * @public
		 * @memberof APP.Head
		 * @returns {APP.Head} - the instance of the module
		 */
		getInstance: function () {

			if (!instance) {
				instance = init();
			}

			return instance;
		}

	};

}());
